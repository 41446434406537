import React from "react"
import ReactApexChart from "react-apexcharts"

const Chartapex = (props) => {
  let total = props?.total_produits

  if(props?.total_produits > props.total_ventes){
    total = props?.total_produits 
  }else if(props?.total_ventes > props.total_produits){
    total = props?.total_ventes 
  }

  if(total <= 20){
    total = total + 5
  }else if(total > 20 && total <= 50){
    total = total + 10
  }else if(total > 50 && total <= 100){
    total = total + 20
  }else if(total > 100 && total <= 200){
    total = total + 30
  }else if(total > 200 && total <= 500){
    total = total + 50
  }else if(total > 500 && total <= 1000){
    total = total + 100
  }

  const series = [
    { name: `Ventes effectuées`, data: props?.ventes },
    { name: `Produits achetés`, data: props?.achats },
  ]
  const options = {
    chart: {
      height: 380,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#3F6ABF', '#59C1D9'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: 'straight'
    },
    series: [{
      name: "Ventes effectuées",
      data: props?.achats
    },
    {
      name: "Produits achetés",
      data: props?.ventes
    }
    ],
    title: {
      text: 'Achats et Ventes',
      align: 'left',
      style: {
        fontWeight: '500',
      },
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: '#f1f1f1'
    },
    markers: {
      style: 'inverted',
      size: 6
    },
    xaxis: {
      categories: props?.mois,
      title: {
        text: 'Mois'
      }
    },
    yaxis: {
      title: {
        text: 'Quantité'
      },
      min: 0,
      max: total
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          toolbar: {
            show: false
          }
        },
        legend: {
          show: false
        },
      }
    }]
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  )
}

export default Chartapex
