// api.js
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';


const BASE_URL = 'https://core.bdinternationaltrading.com/api/v1/';
//const BASE_URL = 'http://127.0.0.1:8000/api/v1/';

const source = axios.CancelToken.source();


const api = axios.create({
  baseURL: BASE_URL
});



export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};
//const CancelToken = api.CancelToken;
export const logoutService = async () => {
  localStorage.removeItem('authUser');
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
};



api.interceptors.request.use(async req => {
  let authToken = localStorage.getItem('authToken');
  let refreshToken = localStorage.getItem('refreshToken');
  //console.log("Intercepting")

  if (authToken) {
    const tokenInfos = jwtDecode(authToken);
    const isExpired = dayjs.unix(tokenInfos.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      req.headers['Authorization'] = `Bearer ${authToken}`;
      return req;
    }

    try {
      //console.log("refreshing")
      const response = await axios.post(`${BASE_URL}/authentication/refresh/token/`, {
        token: refreshToken
      });

      const newAuthToken = response.data.auth_token;
      const newRefreshToken = response.data.refresh_token;

      localStorage.setItem('authToken', newAuthToken);
      localStorage.setItem('refreshToken', newRefreshToken);

      req.headers['Authorization'] = `Bearer ${newAuthToken}`;
      return req;

    } catch (error) {
      logoutService();
      const navigate = useNavigate();
      navigate('/'); // Redirect to login page
      return Promise.reject(error);
    }
  } else {
    return req;
  }
}, error => {
  return Promise.reject(error);
});

// Types pour les données et les réponses
// Vous pouvez ajuster cela en fonction de la structure de vos données
const Data = {};
const ApiResponse = {};


// Fonction pour récupérer les informations de l'utilisateur depuis le localStorage
export const isUserAuthenticated = () => {
  try {
    // Récupérer les informations de l'utilisateur depuis le localStorage
    const isLoggedInResponse = localStorage.getItem('isLoggedIn');

    // Retourner null si les informations n'existent pas
    if (!isLoggedInResponse) {
      return false;
    }

    // Convertir la chaîne JSON en objet JavaScript
    const isLoggedIn = JSON.parse(isLoggedInResponse);

    return isLoggedIn;
  } catch (error) {
    console.error('Error getting user info from localStorage:', error);
    throw error;
  }
};

// Fonction pour récupérer les informations de l'utilisateur depuis le localStorage
export const getUserInfoFromLocalStorage = () => {
  try {
    // Récupérer les informations de l'utilisateur depuis le localStorage
    const userInfoString = localStorage.getItem('authUser');

    // Retourner null si les informations n'existent pas
    if (!userInfoString) {
      return null;
    }

    // Convertir la chaîne JSON en objet JavaScript
    const userInfo = JSON.parse(userInfoString);

    return userInfo;
  } catch (error) {
    console.error('Error getting user info from localStorage:', error);
    throw error;
  }
};


// Méthode générique pour gérer les requêtes GET
export const get = (endpoint) => {
  return new Promise((resolve, reject) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      };

      setAuthToken(authToken);
      api.get(endpoint, { headers })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

// Méthode générique pour gérer les requêtes POST
export const post = (endpoint, data) => {
  return new Promise((resolve, reject) => {
    try {
      const authToken = localStorage.getItem('authToken');

      setAuthToken(authToken);
      api.post(endpoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error('Error creating data:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error creating data:', error);
      reject(error);
    }
  });
};

// Méthode générique pour gérer les requêtes PUT
export const put = (endpoint, data) => {
  return new Promise((resolve, reject) => {
    try {
      const authToken = localStorage.getItem('authToken');

      setAuthToken(authToken);
      api.put(endpoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error('Error updating data:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error updating data:', error);
      reject(error);
    }
  });
};


// Méthode générique pour gérer les requêtes DELETE
export const remove = async (endpoint) => {
  return new Promise((resolve, reject) => {
    try {
      const authToken = localStorage.getItem('authToken');
      setAuthToken(authToken);
      
      api.delete(endpoint)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error('Error deleting data:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error deleting data:', error);
      reject(error);
    }
  });
};


///////////////////////////////////////////////ss//////////////////////////////////

