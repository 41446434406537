import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderId = (cell) => {
    const standartId = (id) => {
      const str_id = id?.toString(); // Convertir l'ID en chaîne de caractères
      if (str_id?.length === 1) {
        return `F0000${str_id}`;
      } else if (str_id?.length === 2) {
        return `F000${str_id}`;
      } else if (str_id?.length === 3) {
        return `F00${str_id}`;
      } else if (str_id?.length === 4) {
        return `F0${str_id}`;
      } else if (str_id?.length === 5) {
        return `F${str_id}`;
      }
      // Retourner str_id dans le cas par défaut pour les ID qui ne sont pas de longueur 1 à 5
      return `F${str_id}`;
    }
    return (
        <Link to={`/facture/${cell.row.original.id}`} className="text-body fw-bold">{cell.value ? standartId(cell.value) : ''}</Link>
    );
};

const ClientName = (cell) => {
    return cell.value ? cell.row.original.client.fullname : '';
};

const CreationDate = (cell) => {
    return cell.value ? new Date(cell.value).toLocaleString('fr-FR') : '';
};

const Total = (cell) => {
    return cell.value ? `${(cell.value).toLocaleString()} XOF`  : '';
};

const PaymentStatus = (cell) => {
    return cell.value ? `${cell.row.original.user.first_name} ${cell.row.original.user.last_name}` : '';
};
const PaymentMethod = (cell) => {
    return cell.value ? cell.value.length : '';
};
export {
    CheckBox,
    OrderId,
    ClientName,
    CreationDate,
    Total,
    PaymentStatus,
    PaymentMethod
};