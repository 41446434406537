/**
 * Converts a file to a base64 encoded string.
 *
 * @param {File} file - The file to be converted.
 * @returns {Promise<string | undefined>} The base64 encoded string of the file or undefined if the input is not a file.
 */
async function convertImageToBase64(file) {
    if (typeof file === "object" && file instanceof File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject("Error reading file");
        };
      });
    } else {
      return undefined;
    }
  }
  
  export default convertImageToBase64;
  