import React, {forwardRef} from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { map } from "lodash";
import bditoffical from 'assets/images/bditoffical.png';

const PrintableInvoice  = forwardRef((props, ref) => {
    const rowStyle = {
      display: 'flex',
      flexWrap: 'nowrap'
    };
    return(
        <div ref={ref}>

        <Row>
        <Col lg="12">
          <Card>
            <CardBody className="mx-2">
              <div className="invoice-title">
                <div className="float-end">

                <h4 className="float-end font-size-20">
                  Facture  
                </h4><br/>
                <span className="float-end">Dakar, le {props?.creationDate ? new Date(props?.creationDate).toLocaleDateString() : null}</span>
                </div>
                <div className="auth-logo mb-2">
                  
                <img src={bditoffical} alt="logo-dark" className="logo-dark-element" height="100" />
                <img src={bditoffical} alt="logo-light" className="logo-light-element" height="100" />
                </div>
              </div>
              <hr />
              <Row>
                <Col sm="6">
                  <address>
                    <strong>BD INT-TRADING</strong>
                    <br />
                    <span>Mbour, Thies</span>
                    <br />
                    <span>+221-33-987-65-43</span>
                    <br />
                    <span>contact@bdinttrading.com</span>
                    <br />
                  </address>
                </Col>
                <Col sm="6" className="text-sm-end">
                  <address>
                    <strong>{props?.client?.fullname}</strong>
                    <br />
                    <span>Dakar Hann</span>
                    <br />
                    <span>{props?.client?.phone}</span>
                    <br />
                    <span>{props?.client?.email}</span>
                    <br />
                  </address>
                </Col>
              </Row>
              <div className="py-2 mt-3">
                <h3 className="font-size-15 fw-bold">Produits</h3>
              </div>
              <div className="table-responsive">
                <Table className="table-nowrap">
                  <thead>
                    <tr>
                      <th style={{ width: "70px" }}>No.</th>
                      <th>Article</th>
                      <th>Quantité</th>
                      <th className="text-end">Price unitaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      props?.articles,
                      (item, key) => (
                        <tr key={key}>
                          <td>{item?.product.id}</td>
                          <td>{item?.product.title}</td>
                          <th>{item?.quantity}</th>
                          <td className="text-end">{item?.price} XOF</td>
                        </tr>
                      )
                    )}
                    {/* <tr>
                      <td colSpan="3" className="text-end">
                        Total HT
                      </td>
                      <td className="text-end">
                        xxxxx
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="border-0 text-end">
                        <strong>TVA...%</strong>
                      </td>
                      <td className="border-0 text-end">
                        xxxxx
                      </td>
                    </tr> */}
                    <tr>
                      <td colSpan="3" className="border-0 text-end">
                        <strong>Total </strong>
                      </td>
                      <td className="border-0 text-end">
                        {props?.total} XOF
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="d-print-none">
                <div className="float-end">
                  <Link
                    to="#"
                    onClick={props?.printInvoice}
                    className="btn btn-success  me-2"
                  >
                    <i className="fa fa-print" />
                  </Link>
                  <Link to="#" className="btn btn-primary w-md ">
                    Envoyer
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </div>
    )
});

PrintableInvoice.displayName = "PrintableInvoice";
export default PrintableInvoice;