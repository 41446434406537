import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { size, map } from "lodash";


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};


const Email = (cell) => {
    return cell.value ? cell.value : '';
};

const Poste = (cell) => {
    return (
        
        <Link
        to="#"
        className="badge badge-soft-primary font-size-11 m-1"
    >
        {cell.value ? cell.value : ''}
    </Link>
    )
};

const Role = (cell) => {
    return (
        
        <Link
        to="#"
        className="badge badge-soft-primary font-size-11 m-1"
    >
        {cell.value ? cell.value : ''}
    </Link>
    )
};

const Phone = (cell) => {
    return cell.value ? cell.value : '';
};
const Status = (cell) => {
    return cell.value ? 'Actif' : 'Inactif';
};

const Img = (cell) => {
    return (
        <>
            {!cell.value ? (
                <div className="avatar-xs">
                    <span className="avatar-title rounded-circle">
                        {cell.data[0].name.charAt(0)}
                    </span>
                </div>
            ) : (
                <div>
                    <img
                        className="rounded-circle avatar-xs"
                        src={cell.value}
                        alt=""
                    />
                </div>
            )}
        </>
    );
};

export {
    Email,
    Poste,
    Phone,
    Img,
    Status,
    Role
};