import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useNavigate } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import marketbi from "assets/images/marketbi.png"
import { LoginService } from "helpers/loginServices";
import bditoffical from 'assets/images/bditoffical.png';

const Login = props => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null)
  const [isConnecting, setIsConnecting] = useState(false)

  //meta title
  document.title = "Login | BD International Trading";

  const dispatch = useDispatch();
  const [authUser, setAuthUser] = useState('null');

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser) {
        
        navigate("/dashboard");
      }
    }
  }, [props.success]);


  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || '',
      password: "" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      setIsConnecting(true);
      //console.log(JSON.stringify(values))
      
      const response = await LoginService(values)
      if(response.status === 200){
        let responseData = response.data
        //console.log(token)
        localStorage.setItem("authUser", JSON.stringify(responseData?.user))
        localStorage.setItem("authToken", responseData.auth_token)
        localStorage.setItem("refreshToken", responseData.refresh_token)
        navigate("/dashboard")

      }else if(response?.status === 401 ){
        setIsConnecting(false);
        setLoginError("Email ou mot de passe incorrect!")
      }else if(response?.status === 404) {
        setIsConnecting(false);
        setLoginError("Email ou mot de passe incorrect!")
      } else {
        setIsConnecting(false);
        setLoginError("Email ou mot de passe incorrect!")
      }
    }
  });


  const selectLoginState = (state) => state.Login;
    const LoginProperties = createSelector(
      selectLoginState,
        (login) => ({
          error: login.error          
        })
    );

    const {
      error
  } = useSelector(LoginProperties);

    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={6}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                  <Col className="col-12">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Bienvenue !</h5>
                        <p>Connectez-vous pour accéder au tableau de bord.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      {/* <img src={profile} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={bditoffical}
                            alt=""
                            className="rounded-circle"
                            height="90"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {loginError ? <Alert color="danger">{loginError}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Mot de passe</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Se rappeler de moi
                        </label>
                      </div>

                      {isConnecting ? (
                        <div className="mt-3 d-grid">
                          <div className="text-center">
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ):
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Se connecter
                          </button>
                        </div>
                      }

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Se connecter avec</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                          {/* <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link> */}
                          </li>
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Mot de passe oublié?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                
                <p>
                  © {new Date().getFullYear()} BD Internatinal Trading
                  {/* <i className="mdi mdi-heart text-danger" /> by Themesbrand */}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
