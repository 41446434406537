import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { Link, useNavigate } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import marketbi from "../../assets/images/marketbi.png";

const Register = props => {

  //meta title
  document.title = "Register | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      password1: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Veuillez renseigner votre Email"),
      firstname: Yup.string().required("Veuillez renseigner votre prénom"),
      lastname: Yup.string().required("Veuillez renseigner votre nom"),
      phone: Yup.string().required("Veuillez renseigner votre numéro de téléphone"),
      password1: Yup.string().required("Veuillez confirmer votre mot de passe"),
      password: Yup.string().required("Veuillez renseigner un mot de passe"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });


  const selectAccountState = (state) => state.Account;
  const AccountProperties = createSelector(
    selectAccountState,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success
      // loading: account.loading,
    })
  );

  const {
    user,
    registrationError, success
    // loading
  } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

useEffect(() => {
  success && setTimeout(() => navigate("/login"), 2000)
}, [success])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-8">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Skote account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={marketbi}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                      <div className="d-flex justify-content-around">

                        <div className="mb-3 col-5">
                          <Label className="form-label">Prénom</Label>
                          <Input
                            name="firstname"
                            type="text"
                            placeholder="Entrer prénom"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstname || ""}
                            invalid={
                              validation.touched.firstname && validation.errors.firstname ? true : false
                            }
                          />
                          {validation.touched.firstname && validation.errors.firstname ? (
                            <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 col-5">
                          <Label className="form-label">Nom</Label>
                          <Input
                            name="lastname"
                            type="text"
                            placeholder="Entrer nom"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastname || ""}
                            invalid={
                              validation.touched.lastname && validation.errors.lastname ? true : false
                            }
                          />
                          {validation.touched.lastname && validation.errors.lastname ? (
                            <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-around">
                        <div className="mb-3 col-5">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-5">
                          <Label className="form-label">Téléphone</Label>
                          <Input
                            id="phone"
                            name="phone"
                            className="form-control"
                            placeholder="Entrer numéro de téléphone"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone && validation.errors.phone ? true : false
                            }
                          />
                          {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-around">
                        <div className="mb-3 col-5">
                          <Label className="form-label">Mot de passe</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Choisir nouveau mot de passe"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-5">
                          <Label className="form-label">Confirmer mot de passe</Label>
                          <Input
                            name="password1"
                            type="password"
                            placeholder="Confirmer mot de passe"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password1 || ""}
                            invalid={
                              validation.touched.password1 && validation.errors.password1 ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password1 ? (
                            <FormFeedback type="invalid">{validation.errors.password1}</FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-4 d-flex justify-content-around">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Créer un compte
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Skote{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
