import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

// {
//     id: 4,
//     title: "ff",
//     description: "",
//     quantity: 45,
//     total_purchase_price: 44,
//     unit_selling_price: 55,
//     nbrAchat: 0,
//     private_id: "1",
//     created_date: "2024-04-23T16:05:19.473797Z",
// }
const ProductId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const Title = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const CreatedDate = (cell) => {
    return cell.value ? new Date(cell.value).toLocaleString('fr-FR') : '';
};

const Price = (cell) => {
    return cell.value ? `${(cell.value).toLocaleString()} XOF`  : 0;
};

const Quantity = (cell) => {
    return <div className='text-center'>{cell.value ? `${(cell.value).toLocaleString()}` : `0`}</div>;
};
const Sold = (cell) => {
    return <div className='text-center'>{cell.value ? `${cell.value}` : `0 `}</div>;
};
export {
    ProductId,
    Title,
    CreatedDate,
    Price,
    Quantity,
    Sold
};