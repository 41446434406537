import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper";
import { post } from "helpers/apiServices";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

const registerAxios = async (formData) => {
  try {
    console.log("Hello")
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
  
}

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  console.log("using the following url for registration: ")
  try {
    const payload = {
      email: user.email,
      phone: user.phone,
      nom: user.lastname,
      prenom: user.firstname,
      password: user.password,
      password1: user.password1
    }
    const response = yield call(registerAxios, payload)
    console.log(response)
    yield put(registerUserSuccessful(response))
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
