import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap";
import images from "assets/images";

const CardInvoice = ({ data }) => {
  const name = data.founder;
  //const nameIcon = name.charAt(0);

  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card>
          <CardBody>
            <Row>
              <Col lg="4">
                <div className="text-lg-center">
                  
                    <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle">
                      <span
                        className={
                          "avatar-title rounded-circle bg-primary-subtle text-primary font-size-16"
                        }
                      >
                        {data?.client.first_name.charAt(0)} {data?.client.last_name.charAt(0)}
                      </span>
                    </div>

                  <h5 className="mb-1 font-size-15 text-truncate">
                  {data?.client.first_name.charAt(0)} {data?.client.last_name}
                  </h5>
                  <Link to="#" className="text-muted">
                    @Client
                  </Link>
                </div>
              </Col>

              <Col lg="8">
                <div>
                  <Link
                    to={`/invoices-detail/${data?.id}`}
                    className="d-block text-primary text-decoration-underline mb-2"
                  >
                    Facture {data?.private_id?data?.private_id:"#F123"}
                  </Link>
                  <h5 className="text-truncate mb-4 mb-lg-5">{data?.user.prenom} {data?.user.nom}</h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item me-3">
                      <h5 className="font-size-14" id="amountTooltip">
                        <i className="bx bx-money me-1 text-muted" /> XOF{" "}
                        {data?.total}
                        <UncontrolledTooltip
                          placement="top"
                          target="amountTooltip"
                        >
                          Total Payé
                        </UncontrolledTooltip>
                      </h5>
                    </li>{" "}
                    <li className="list-inline-item me-3">
                      <h5 className="font-size-14" id="duedateTooltip">
                        <i className="bx bx-calendar me-1 text-muted" />{" "}
                        {new Date(data?.created_date).toLocaleDateString()}
                        <UncontrolledTooltip
                          placement="top"
                          target="duedateTooltip"
                        >
                          Date de la vente
                        </UncontrolledTooltip>
                      </h5>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardInvoice.propTypes = {
  data: PropTypes.any,
};

export default CardInvoice;
