import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";

// FlatPickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import { get, remove } from "helpers/apiServices";

import {
  getProjects as onGetProjects,
  addNewProject as onAddNewProject,
  updateProject as onUpdateProject,
  deleteProject as onDeleteProject,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import Paginations from "components/Common/Pagination";
import { ToastContainer, toast } from "react-toastify";

const FactureStatus = ({ status }) => {
  switch (status) {
    case "Initiation":
      return <Badge className="bg-danger"> {status} </Badge>;

    case "Conception":
      return <Badge className="bg-primary"> {status} </Badge>;

    case "Planification":
      return <Badge className="bg-info"> {status} </Badge>;

    case "Conception":
      return <Badge className="bg-warning"> {status} </Badge>;

    case "Exécution":
      return <Badge className="bg-secondary"> {status} </Badge>;

    case "Contrôle":
      return <Badge className="bg-light"> {status} </Badge>;

    case "Clôture":
        return <Badge className="bg-success"> {status} </Badge>;

    default:
      return <Badge className="bg-success"> {status} </Badge>;
  }
};

const FacturesList = () => {

  //meta title
  document.title = "Ventes | BD International Trading";

  const dispatch = useDispatch();
  const [project, setProject] = useState();
  const [bill, setBill] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [isShowingPageLength, setIsShowingPageLength] = useState(false)
  
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 10;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;
  const [count, setCount] = useState(0);
  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (project && project.id) || "",
      img: (project && project.img) || "",
      name: (project && project.name) || "",
      description: (project && project.description) || "",
      status: (project && project.status) || "",
      color: (project && project.color) || "",
      dueDate: (project && project.dueDate) || "",
      team: (project && project.team) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        "Please Enter Your Name"
      ),
      description: Yup.string().required(
        "Please Enter Your Description"
      ),
      status: Yup.string().required(
        "Please Enter Your Status"
      ),
      color: Yup.string().required(
        "Please Enter Your Color"
      ),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateProject = {
          id: project.id,
          img: values.img,
          name: values.name,
          description: values.description,
          status: values.status,
          color: values.color,
          dueDate: values.dueDate,
          team: values.team,
        };

        // update project
        dispatch(onUpdateProject(updateProject));
      } else {
        const newProject = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          description: values["description"],
          status: values["status"],
          color: values["color"],
          dueDate: values["dueDate"],
          team: values["team"],
        };
        // save new project
        dispatch(onAddNewProject(newProject));
      }
      toggle();
    },
  });


  const [isLoading, setLoading] = useState(true)
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [billsList, setBillsList] = useState([]);
  const totalPages = useMemo(() => Math.ceil(count / perPageData), [count, perPageData]);
  console.log("totalPages: ", totalPages)
  const pageNumbers = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }, [totalPages]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setProject(null);
    } else {
      setModal(true);
    }
  };
  const standartId = (id) => {
    const str_id = id?.toString(); // Convertir l'ID en chaîne de caractères
    if (str_id?.length === 1) {
      return `0000${str_id}`;
    } else if (str_id?.length === 2) {
      return `000${str_id}`;
    } else if (str_id?.length === 3) {
      return `00${str_id}`;
    } else if (str_id?.length === 4) {
      return `0${str_id}`;
    } else if (str_id?.length === 5) {
      return `${str_id}`;
    }
    // Retourner str_id dans le cas par défaut pour les ID qui ne sont pas de longueur 1 à 5
    return str_id;
  }
  
  const handleProjectClick = arg => {
    const project = arg;

    setProject({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    });

    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (billToDelete) => {
    setBill(billToDelete);
    setDeleteModal(true);
  };

  const handleDeleteOrder = async () => {
    
    if (bill && bill?.id) {
      try {
        const response = await remove(`/bills/${bill?.id}`);
        setDeleteModal(false);
        if(response.status === 204){
          const allProduct = await get('bills/');
          setBillsList(allProduct?.data?.results);
          toast.success("Produit supprimé avec succès", { autoClose: 2000 });
        }
      } catch (error) {
        console.log("Erreur: ", error);
      }
    }
    setDeleteModal(false);
  };


  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('bills/');
        console.log(response?.data)
        setBillsList(response?.data?.results);
        setCount(response?.data?.count);
      } catch (error) {
        console.error('Error while getting projects: ', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);
  const handlePrevPage = async () => {
    try {
      const response = await get(`projects/?p=${currentPage - 1}`);
      console.log(response?.data)
      setBillsList(response?.data?.results);
      setCount(response?.data?.count);
    } catch (error) {
      console.error('Error while getting projects: ', error);
      // Gérer l'erreur ici
    }
    console.log('pageNumber: ', currentPage - 1);
    setCurrentPage(currentPage - 1);
  };
  
  const handleNextPage = async () => {
    try {
      const response = await get(`projects/?p=${currentPage + 1}`);
      console.log(response?.data)
      setBillsList(response?.data?.results);
      setCount(response?.data?.count);
    } catch (error) {
      console.error('Error while getting projects: ', error);
      // Gérer l'erreur ici
    }
    console.log('pageNumber: ', currentPage + 1);
    setCurrentPage(currentPage + 1);
  };
  
  const handleClick = async (pageNumber) => {
    try {
      const response = await get(`bills/?p=${pageNumber}`);
      console.log(response?.data)
      setBillsList(response?.data?.results);
      setCount(response?.data?.count);
    } catch (error) {
      console.error('Error while getting projects: ', error);
      // Gérer l'erreur ici
    }
    console.log('pageNumber: ', pageNumber);
    setCurrentPage(pageNumber);
  };
  const handleSearch = async (e) => {
    try {
      setSearchTerm(e.target.value);
      const response = await get(`bills/?search=${e.target.value}`);
      console.log(response?.data?.results)
      setBillsList(response?.data?.results);
      setCount(response?.data?.count);
    } catch (error) {
      console.error('Error while getting projects: ', error);
      // Gérer l'erreur ici
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Facturation" breadcrumbItem="Ventes" />
          <Row className="mb-3">
            <Col>
              <Input
                type="text"
                placeholder="Rechercher facture..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </Col>
          </Row>

          {
            isLoading ? <Spinners setLoading={setLoading} /> :
              <Row>
                <Col lg="12">
                  <div>
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Client</th>
                            <th scope="col">Date de vente</th>
                            <th scope="col">Montant</th>
                            <th scope="col">Vendeur(se)</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(billsList, (bill, index) => (
                            <tr key={index}>
                              <td>
                                <h5 className="text-truncate font-size-14">
                                  <Link
                                    to={`/facture/${bill?.id}`}
                                    className="text-dark"
                                  >
                                    F#{standartId(bill?.id)}
                                  </Link>
                                </h5>
                              </td>
                              <td> {bill?.client?.fullname}</td>
                              <td> {bill?.created_date ? new Date(bill?.created_date).toLocaleString('fr-FR') : ''}</td>
                              <td>
                              {bill?.total} XOF
                              </td>
                              <td>
                                  <Link
                                    to="#"
                                    className="text-dark">
                                      {bill?.user?.first_name} {bill?.user?.last_name}
                                  </Link>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    className="card-drop"
                                    tag="a"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                      href="#"
                                      onClick={() => handleProjectClick(bill)}
                                    >
                                      <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                      Modifier
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#"
                                      onClick={() => onClickDelete(bill)}
                                    >
                                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                      Supprimer
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Project" : "Add Project"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <Input
                      type="hidden"
                      value={validation.values.img || ""}
                      name="img"
                    />

                    <Input
                      type="hidden"
                      value={validation.values.team || ""}
                      name="team"
                    />
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <Input
                        name="description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description && validation.errors.description ? true : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Input
                        name="status"
                        id="status1"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.status || ""
                        }
                      >
                        <option>Completed</option>
                        <option>Pending</option>
                        <option>Delay</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Color</Label>
                      <Input
                        name="color"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.color || ""}
                        invalid={
                          validation.touched.color && validation.errors.color ? true : false
                        }
                      >
                        <option>success</option>
                        <option>warning</option>
                        <option>danger</option>
                      </Input>
                      {validation.touched.color && validation.errors.color ? (
                        <FormFeedback type="invalid">{validation.errors.color}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">dueDate</Label>
                      <FlatPickr
                        className="form-control"
                        name="dueDate"
                        options={{
                          dateFormat: "d M,Y"
                        }}
                        value={validation.values.dueDate || ""}
                        onChange={(date) => validation.setFieldValue("dueDate", moment(date[0]).format("DD MMMM, YYYY"))}
                      />
                      {validation.touched.dueDate && validation.errors.dueDate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.dueDate}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          
          <Row className="justify-content-between align-items-center">
            {isShowingPageLength && (
              <div className="col-sm">
                <div className="text-muted">
                  Showing <span className="fw-semibold">{perPageData}</span> of <span className="fw-semibold">{projectList.length}</span> entries
                </div>
              </div>
            )}
            <div className="col-12">
              <ul className="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1">
                <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
                  <Link className="page-link" to="#" onClick={handlePrevPage}>
                    <i className="mdi mdi-chevron-left"></i>
                  </Link>
                </li>
                {pageNumbers.map((item, index) => (
                  <li className={currentPage === item ? "page-item active" : "page-item"} key={index}>
                    <Link className="page-link" to="#" onClick={() => handleClick(item)}>
                      {item}
                    </Link>
                  </li>
                ))}
                <li className={`page-item ${currentPage >= totalPages ? "disabled" : ""}`}>
                  <Link className="page-link" to="#" onClick={handleNextPage}>
                    <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Row>

        </Container>
      </div>
      <ToastContainer />
    </React.Fragment >
  );
};

export default withRouter(FacturesList);
